#formInfosDiv {
  width: 100%;
  margin-bottom: 15px;
  .formInfosClass {
    width: 100%;
    padding: 15px;
    label {
      width: 15%;
      display: inline-block;
    }
    input {
      width: 85%;
    }
  }

  #errorMessage {
    width: 25%;
    margin: 0px auto;
    text-align: center;

    span {
      font-size: larger;
      font-weight: bold;
    }
  }

  &.error {
    border: 2px solid rgba(255, 0, 0, 0.473);
    background-color: rgba(255, 0, 0, 0.199);
    border-radius: 15px;
  }
}

.btn.btn-primary.btn-sm.btn-block.formcomponent.drag-copy{
  width: 100%;
}