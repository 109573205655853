@import "../../variable.scss";

.full_header {
  display: grid;
  grid-template-columns: 97.5% 2.5%;

  ul{
    height: 100%;
  }

  button.nav-link{
    padding-bottom: 0px;
    height: 100%;
  }

  .quitButton {
    background-color: transparent;
    color: red;
    border: none;
    font-size: larger;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border: 1px solid transparent;
    border-bottom: 1px solid #dee2e6;

    &:hover {
      border-left: 1px solid #dee2e6;
    }
  }
}

.loadContainer {
  position: absolute;
  .loader_bg {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    justify-content: center;
    align-items: center;

    span {
      color: white;
      font-size: larger;
      font-weight: bolder;
      margin-top: -12.5vh;
    }
  }
}

.annot_body {
  button.btn-primary:disabled {
    visibility: visible;
  }

  label {
    margin-top: 0px;
  }

  .annotationWrapper {
    // display: flex;
    height: 100%;
    display: grid;
    grid-template-columns: 80% 20%;

    .annotationEditor {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80%;
      height: fit-content;
      margin: 10px;

      .toolbar_toggle {
        position: absolute;
        z-index: 150;
        top: 0px;
        left: 0px;
      }

      // Edit selection data
      .editorWrapper {
        position: absolute;
        background-color: white;
        border-radius: 2px;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 3px 1px -2px rgba(0, 0, 0, 0.12);
        margin-top: 10px;

        .editorContent {
          padding: 8px 16px;

          .valInput {
            margin-top: 5px;
          }
        }

        .editorSubmit {
          background-color: whitesmoke;
          border: 0;
          box-sizing: border-box;
          color: black;
          cursor: pointer;
          margin: 0;
          outline: 0;
          padding: 8px 16px;
          width: 100%;

          button {
            text-align: center;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
            font-size: 1rem;
            background-color: transparent;
            border: none;
            width: 100%;
          }
        }
      }

      // The selector render
      .selectorTrace {
        position: absolute;

        &.highlight {
          color: black;
          font-weight: bold;
          font-size: larger;
        }

        &.rectangle {
          background-color: gray;
          border: 1px solid black;
          opacity: 0.4;

          &.highlight {
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.point {
          background-color: white;
          border: 1px solid black;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          opacity: 0.8;

          &.highlight {
            opacity: 1;
            .pointText {
              position: relative;
              top: 12.5px;
              left: 12.5px;
            }
          }
        }

        &.draw,
        &.polygon {
          width: 100%;
          height: 100%;

          path {
            stroke: black;
            fill: transparent;
            stroke-width: 2px;
          }

          &.highlight {
            path {
              fill: gray;
              opacity: 0.6;
            }
          }
        }
      }
    }

    .toolBar {
      // width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-left: 1px solid black;
      overflow-y: scroll;
      max-height: 91.5vh;

      .main_tools {
        width: 100%;
        padding: 5px 15px;

        .polygonGrouping {
          padding: 7.5px 0px;
          .polygonGroup {
            width: 100%;
            flex-wrap: wrap;

            button{
              border-radius: 0px;
              margin: 0px;
            }
          }
        }

        .annotationList {
          padding: 7.5px 0px;
          .annotationItem {
            padding: 2.5px 0px;
            .annot_overview {
              width: 100%;
              display: flex;

              .nameInput{
                flex-grow: 1;
              }
            }

            .annot_edit {
              padding: 5px;
              background-color: whitesmoke;
              margin-top: 15px;
              margin-bottom: 25px;
              box-shadow: $bigBox-shadow;

              .valInput {
                margin-top: 5px;
              }
            }
          }
        }
      }

      .close_menu {
        padding: 15px;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        button {
          margin: 5px 0px;
          padding: 7.5px 25px;
        }
      }
    }
  }

  .uploaderTab {
    display: flex;
    margin-top: 50px;

    .uploadCard {
      background-color: whitesmoke;
      width: 50%;

      .formInput {
        margin: 10px 0px;
      }

      .form-confirm {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
      }
    }
  }
}
