//#region Node
.mainDivElement {
     max-height: 100%;
     max-width: 100%;
     overflow: hidden;
}

.defaultElem {
     width: 150px;
     height: 150px;
     font-weight: 500;
     letter-spacing: 1px;
     border-radius: 15px;
     font-family: "Roboto", sans-serif;
     border: 1px solid rgb(156, 156, 156);
     box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

     .slideMenuButton {
          width: 100%;
          height: 100%;
          border: none;
          background-color: transparent;
     }

     .name {
          width: 90%;
          margin: 0px auto;
          font-size: small;
          text-align: center;
          background-color: transparent;
          p {
               margin-bottom: 8px;
          }
     }

     .otherInfos {
          display: grid;
          grid-template-columns: 100%;

          .img {
               width: 60%;
               text-align: center;
               margin: 0px auto;
               img {
                    width: 50%;
                    max-width: 100%;

                    &.linked {
                         cursor: pointer;
                    }
               }
          }
     }
}

.defaultElem:hover {
     transition: 0.3s ease;
     color: rgba(37, 37, 37, 1);
     transform: translateY(-3.5px);
     box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4);
}
//#endregion

//#region Menu
.elemTitle {
     &.light {
          color: black !important;
     }
     &.dark {
          color: white !important;
     }
     text-align: center;
}

.buildImgWrapper {
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     .buildingImg {
          width: 90%;
          border: 1px solid white;
          min-height: 100px;
          cursor: zoom-in;

          &.dark {
               border-color: black;
          }
     }
}

.menuBody {
     .background-img {
          position: absolute;
          top: 37.5%;
          left: 25%;
          width: 50%;
          height: 25%;
          opacity: 0.3;
     }

     .buttonGroups {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: center;

          .singleButton {
               padding: 4%;
               margin: 2px 2px;
               border-width: 3px;
               i {
                    width: 30px;
                    font-size: x-large;
               }
          }
     }
}
//#endregion
