@import "src/variable";

.accordion-header_2 {
  display: grid;
  grid-template-columns: 80% 20%;
  overflow: hidden;
  min-height: 50px;
  color: $white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;

  &.double {
    grid-template-columns: 70% 30%;
  }

  & p {
    margin: 0;
    margin-left: 5%;
    display: inline-block;
    width: 75%;
    font-size: 20px;
  }

  .accordion-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    align-content: space-around;
    justify-content: center;

    &.double {
      align-content: center;
      justify-content: space-evenly;
    }

    .accordion-indicator {
      height: min-content;
      font-weight: bolder;
      font-size: 30px;
    }

    .buttonDiv {
      .editButton {
        background-color: transparent;
        border: none;
        font-size: 17.5px;
      }
    }
  }
}
.accordion-body {
  padding-left: 5px;
  border-left: solid 4px $base;
  max-width: 100%;

  p.value {
    max-width: 100%;
    word-break: break-all;
    white-space: normal !important;
  }
}

.headerTitle {
  height: max-content;
}
