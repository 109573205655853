@import "src/variable";

.event {
  overflow: hidden;
  box-shadow: $btn-shadow;
  margin: 10px;
  border-radius: 5px;

  &.separated {
    display: grid;
    grid-template-columns: 10% auto;
  }

  .trash {
    margin: auto;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  & .accordion-header_2 {
    font-weight: bolder;
    padding-left: 5px;
  }
  & .accordion-body {
    padding-bottom: 2px;
  }
  .value {
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 2px;
    font-size: 15px;
    color: $black;
  }
  .line {
    display: grid;
    grid-template-rows: auto auto;
    h6 {
      margin-bottom: 0;
    }
    h6,
    .value {
      white-space: nowrap;
    }
  }
}