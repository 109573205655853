@import "../../../variable.scss";

.mainContainerTreeSelect {
  height: 62.5vh;
  overflow-y: auto;
  padding: 10px;

  .searchBarPanel {
    display: flex;
    height: 10%;
    align-items: center;
    justify-content: center;

    input {
      width: 30%;
      border: none;
      border-bottom: 1px solid gray;
    }

    button {
      border: none;
      background-color: transparent;
      font-size: larger;
    }
  }

  .buttonsPanel {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;
  }

  .boxesContainer {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    height: 85%;

    .boxContainer {
      height: 100%;
      overflow-y: scroll;
      margin: 0px 15px;
      padding: 5px 10px;
      box-shadow: $bigBox-shadow;

      .titleDiv {
        display: flex;
        padding: 10px;
        justify-content: center;
        span {
          font-size: larger;
          text-decoration: underline;
          font-weight: bold;
        }
      }

      .boxType {
        span {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          &.selected {
            color: blue;
            font-weight: bold;
          }
        }
      }
    }
  }
}
