@import "../../variable.scss";
.ticketPage{
    height: 100%;
    .myCard{
        height: 97.5%;
        width: 95%;
        max-width: 500px;
        max-height: 650px;
        margin: auto;
        box-shadow: $cart-shadow;
        border-radius: 5px;
    }
    .card-footer{
        min-height: 50px !important;
        button{
            float: right;
            margin: 0 !important;
            margin-right: 10px !important;
        }
    }
    a{
        margin-left: 10px;
        float: left;
        text-align: center;
        color: $base !important;
        font-weight: normal;
        font-size: 14px;
        &:hover{
            cursor: pointer;
        }
    }
    
    .myValue{
        display: block;
        input{
            width: 100%;
        }
    }
    button.btn.btn-primary.btn-md{
        display: none;
    }
}
