@import "src/variable";

.myCard {
     display: flex;
     flex-direction: column;
     margin: 0;
     position: relative;
     height: 100%;
     & .card-header {
          .headerTitle {
               font-size: x-large;
               font-weight: bold;
          }

          // min-height: 50px;
          // flex-grow: 0.5;
          border: none;
          background: rgba($base-white, 0.5);
          // flex-basis: auto;
          margin: 0;
          position: relative;
          & h3 {
               height: 50px;
               display: inline;
               margin: 0 10px;
               font-size: 30px;
          }

          .save-btn {
               position: absolute;
               right: 87.5px;
               top: 2.5px;
               font-size: 30px;
               color: #bfbfbf;
               cursor: pointer;

               &:hover {
                    color: #3973ac;
               }
          }
     }
     & .card-body {
          min-height: 60px;
          padding: 5px;
          position: relative;
          overflow: auto;
     }
     & .card-footer {
          border: none;
          background: none;
          min-height: 70px;
          flex-basis: auto;
          padding: 5px;
          position: relative;
          overflow: auto;
          button {
               float: right;
               margin: 15px;
          }
     }
}
