.formulaJool {
     display: grid;
     grid-template-columns: 50% 50%;

     .mainPanel {
          margin: 10px;
     }

     .inputFormula {
          textarea {
               min-height: 100px;
               width: 100%;
          }

          .dropdownList {
               position: absolute;
               background-color: white;
               border: 1px solid black;
               width: 50%;
               z-index: 101;
               max-height: 35vh;
               overflow-y: scroll;
               margin-top: 0px;

               .option {
                    display: grid;
                    padding: 1.5%;
                    border-bottom: 1px solid gray;
                    cursor: pointer;

                    &.entity {
                         grid-template-columns: 10% auto;
                         .imgContainer {
                              margin: 5px;
                              display: flex;
                              justify-content: center;
                              width: fit-content;

                              img {
                                   max-width: 100%;
                              }
                         }
                    }

                    &.tag {
                         grid-template-columns: 100%;
                    }

                    &.focused {
                         background-color: rgb(90, 144, 161);
                    }

                    &:hover {
                         background-color: lightblue;
                    }

                    &:last-child {
                         border-bottom: none;
                    }

                    p {
                         display: flex;
                         height: 100%;
                         margin-bottom: 0px;
                         align-items: center;
                    }
               }
          }
     }

     .translatedFormula {
          height: 100%;
          textarea {
               width: 100%;
               height: 100%;
               resize: none;
          }
          padding-bottom: 25px;
     }
}
