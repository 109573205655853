$rbt-color-primary: #007bff !default;
$rbt-color-disabled: #495057 !default;
$rbt-color-white: #fff !default;

// For multiple typeahead, restrain size
.typeahead-multiple-restrain-sm {
    .rbt-input-multi {
        overflow: scroll;
        max-height: 5rem;
    }
}

.typeahead-multiple-restrain-md {
    .rbt-input-multi {
        overflow: scroll;
        max-height: 8rem;
    }
}

.typeahead-multiple-restrain-lg {
    .rbt-input-multi {
        overflow: scroll;
        max-height: 12rem;
    }
}

// Hide IE's native "clear" button
.rbt .rbt-input-main::-ms-clear {
    display: none;
}

/**
 * Menu
 */
.rbt-menu {
    margin-bottom: 2px; // Spacing for dropup

    & > .dropdown-item {
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus {
            outline: none;
        }
    }

    &-pagination-option {
        text-align: center;
    }
}

/**
 * Multi-select Input
 */
$rbt-background-color-disabled: #e9ecef !default;

$rbt-border-color-focus: #80bdff !default;
$rbt-border-color-focus-invalid: #dc3545 !default;
$rbt-border-color-focus-valid: #28a745 !default;

$rbt-box-shadow-dimensions: 0 0 0 0.2rem;
$rbt-box-shadow-color: rgba(0, 123, 255, 0.25) !default;
$rbt-box-shadow-color-invalid: rgba(220, 53, 69, 0.25) !default;
$rbt-box-shadow-color-valid: rgba(40, 167, 69, 0.25) !default;

$rbt-color-focus: #495057 !default;
$rbt-placeholder-color: #6c757d !default;

.rbt.disabled {
    .form-control {
        opacity: 0.5;
        background-color: #dce4eb;
    }
}

.rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
    margin: inherit !important;
    padding: 0.5rem !important;

    // Apply Bootstrap focus styles
    &.focus {
        border-color: $rbt-border-color-focus;
        box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color;
        color: $rbt-color-focus;
        outline: 0;
    }

    &.form-control {
        height: auto;
    }

    // BS4 uses the :disabled pseudo-class, which doesn't work with non-inputs.
    &.disabled {
        background-color: $rbt-background-color-disabled;
        opacity: 1;
    }

    &.is-invalid.focus {
        border-color: $rbt-border-color-focus-invalid;
        box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-invalid;
    }

    &.is-valid.focus {
        border-color: $rbt-border-color-focus-valid;
        box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-valid;
    }

    // Apply Bootstrap placeholder styles
    input {
        // Firefox
        &::-moz-placeholder {
            color: $rbt-placeholder-color;
            opacity: 1;
        }

        // Internet Explorer 10+
        &:-ms-input-placeholder {
            color: $rbt-placeholder-color;
        }

        // Safari and Chrome
        &::-webkit-input-placeholder {
            color: $rbt-placeholder-color;
        }
    }

    .rbt-input-wrapper {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -4px;
        margin-top: -1px;
        overflow: hidden;
    }

    .rbt-input-main {
        margin: 1px 0 4px;
    }
}

/**
 * Close Button
 */
.rbt-close {
    z-index: 1;

    &-lg {
        font-size: 1.5rem;
    }
}

/**
 * Token
 */
$rbt-token-background-color: #e7f4ff !default;
$rbt-token-color: $rbt-color-primary !default;

$rbt-token-disabled-background-color: rgba(0, 0, 0, 0.1) !default;
$rbt-token-disabled-color: $rbt-color-disabled !default;

$rbt-token-active-background-color: $rbt-color-primary !default;
$rbt-token-active-color: $rbt-color-white !default;

.rbt-token {
    background-color: $rbt-token-background-color;
    border: 0;
    border-radius: 0.25rem;
    color: $rbt-token-color;
    display: inline-flex;
    line-height: 1rem;
    // TODO: Use `gap` when it's better supported
    margin: 1px 3px 2px 0;

    .rbt-token-label {
        display: inline-flex;
        padding: 0.25rem 0.5rem;

        &:not(:last-child) {
            padding-right: 0.25rem;
        }
    }

    &-disabled {
        background-color: $rbt-token-disabled-background-color;
        color: $rbt-token-disabled-color;
        pointer-events: none;
    }

    &-removeable {
        cursor: pointer;
    }

    &-active {
        background-color: $rbt-token-active-background-color;
        color: $rbt-token-active-color;
        outline: none;
        text-decoration: none;
    }

    & &-remove-button {
        // Hide Bootstrap close button image
        background-image: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        // Override Bootstrap button shadow
        box-shadow: none;
        color: inherit;
        display: flex;
        justify-content: center;
        font-size: inherit;
        font-weight: normal;
        opacity: 1;
        outline: none;
        padding: 0.25rem 0.5rem;
        padding-left: 0;
        text-shadow: none;

        .rbt-close-content {
            // Override `display: none` in BS5 styles.
            display: block;
        }
    }
}

/**
 * Loader + CloseButton container
 */
.rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none; /* Don't block clicks on the input */
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;

    &-lg {
        width: 3rem;
    }

    & .rbt-close {
        margin-top: -0.25rem;
        pointer-events: auto; /* Override pointer-events: none; above */
    }
}

.has-aux .form-control {
    padding-right: 2rem;

    // Position validation icons next to clear button and loader.
    &.is-valid,
    &.is-invalid {
        background-position: right 2rem center;
        padding-right: 4rem;
    }
}

// Default highlight style
.rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
}

/**
 * Input Groups
 */
.input-group > .rbt {
    flex: 1;

    // Form-controls within input-groups have a higher z-index.
    & .rbt-input-hint,
    & .rbt-aux {
        z-index: 5;
    }

    &:not(:first-child) .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(:last-child) .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
