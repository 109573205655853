$base-white: #ebf0fa;
$base-light: #8cb3d9;
$base: #3973ac;
$base-dark: #264d73;

$bleu: #00aaff;
$bleu-dark: #0099e6;
$purple: #9999ff;
$purple-dark: #8080ff;
$red: #ff6666;
$red-dark: #ff4d4d;
$yellow: #ffcc66;
$yellow-dark: #ffc34d;
$green: #00cc66;
$green-dark: #00b359;

$white: #ffffff;
$grey-light: #bfbfbf;
$grey: #909090;
$black: #262626;

$cart-shadow: 0 0 7px rgba($black, 0.3);
$btn-shadow: 0 0 6px rgba($black, 0.2);
$bigBox-shadow : 0 0 22px rgba($black, 0.3);