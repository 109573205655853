.modal-content,
.modal-body,
.modal-footer {
  button.btn:disabled {
    visibility: visible;
  }

  .requiredSpan {
    color: red;
  }

  input,
  textarea,
  select {
    &.invalid {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .invalid-feedback.active {
    display: block;
  }

  .alert-imaged {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.loader_bg_modal {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  position: fixed;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .loader_message {
    color: white;
    font-size: larger;
    font-weight: bolder;
    margin-top: -12.5vh;
  }

  &.error_dark {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

.img_modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }

  .close_button {
    position: fixed;
    top: 0;
    right: 0;
  }

  .img_modal_container {
    display: flex;
    flex-direction: column;
    color: white;
    min-height: 75vh;
    justify-content: space-around;

    .img_modal_top {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;

      .img_modal_top_side {
        .switch {
          cursor: pointer;
          color: white;
        }
      }

      .img_modal_top_middle {
        text-align: center;
        img {
          max-width: 85%;
          max-height: 75vh;
        }

        .src_fail {
          font-size: larger;
          font-weight: bold;
        }
      }
    }

    .img_modal_bottom {
      height: 5vh;
      max-height: 140px;
      text-align: center;
    }
  }
}
