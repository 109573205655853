.defaultElem {
     width: 150px;
     height: 150px;
     font-weight: 500;
     letter-spacing: 1px;
     border-radius: 15px;
     font-family: "Roboto", sans-serif;
     border: 1px solid rgb(156, 156, 156);
     box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

     .slideMenuButton {
          width: 100%;
          height: 100%;
          border: none;
          background-color: transparent;
     }

     .name {
          width: 90%;
          margin: 0px auto;
          font-size: small;
          text-align: center;
          background-color: transparent;
          p {
               margin-bottom: 8px;
          }

          span {
               color: red;
          }
     }

     .otherInfos {
          display: grid;

          &.solo {
               grid-template-columns: 100%;
               .img {
                    width: 60%;
                    margin: 0px auto;
                    img {
                         width: 50%;
                         max-width: 100%;

                         &.linked {
                              cursor: pointer;
                         }
                    }
               }
          }
          &.full {
               grid-template-columns: 40% 60%;
               .img {
                    width: 100%;
                    img {
                         width: 60%;
                         max-width: 70%;
                    }
               }
               .values {
                    .tagElem {
                         font-size: smaller;
                    }
               }
          }

          .img {
               text-align: center;
          }

          .values {
               text-align: center;
               //  overflow: auto;
               max-height: calc(150px / 3);
               .tagElem {
                    cursor: pointer;
                    width: 100%;
                    margin: 0px;

                    &.lost {
                         color: red;
                    }
                    &:hover {
                         color: white;
                    }
                    &.calc {
                         text-decoration: underline;
                         cursor: help;
                         &:hover {
                              color: inherit;
                         }
                    }
               }
          }
     }
}
.defaultElem:hover {
     transition: 0.3s ease;
     color: rgba(37, 37, 37, 1);
     transform: translateY(-3.5px);
     box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4);
}
