/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */

@import "~bootstrap/scss/_functions";
@import "theme/functions";

@import "~bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import "theme/_utilities";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/root";
@import "theme/root";

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import "bootstrap";

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import "theme/theme";

.bg-white {
    --falcon-bg-opacity: 1;
    background-color: rgba(
        var(--falcon-white-rgb),
        var(--falcon-bg-opacity)
    ) !important;
}
