.cardMain {
  width: 50%;
  min-width: 300px;
  margin: 5% auto;
  height: 70vh;
  box-shadow: 0 0 7px rgba(#262626, 0.3);
  background-color: white;
  border-radius: 1.5%;

  .card-header {
    font-weight: bold;
    font-size: large;
    text-align: center;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .imgUploader {
      max-height: 30vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        max-height: 30vh;
        border: 1px solid black;
        max-width: 50%;
      }
    }

    .inputPanel {
      width: 70%;
      display: grid;
      grid-template-columns: 30% 55% 15%;
      align-items: center;
    }
  }
}
