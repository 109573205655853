.searchModule {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  

  .form-control {
    display: block;
    width: 100%;
    padding: 0.3125rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--falcon-input-color);
    background-color: var(--falcon-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--falcon-input-border-color);
    appearance: none;
    border-radius: 0.25rem;
    box-shadow: var(--falcon-box-shadow-inset);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

  .inputGroup {
    display: grid;
    
    grid-template-columns: 100%;
    &.on:not(.fav),
    &.fav:not(.on) {
      grid-template-columns: 87.5% 12.5%;
    }
    &.on.fav {
      grid-template-columns: 75% 12.5% 12.5%;
    }
  }
  .clearbutton {
    position: absolute;
    top: 0;
    border-radius: 5px;
    right: 0px;
    z-index: 2;
    border: none;
    top: 2px;
    height: 30px;
    cursor: pointer;
    color: white;
    background-color: #1e90ff;
    transform: translateX(2px);
}
  //  Input
  .inputSearch {
    width: 100%;
    background-color: var(--custom-background-color);
    //border: 1px solid lightgray;
    border: 1px solid var(--falcon-input-border-color);
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto 20px;


    .clearSearches {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;

      button {
        background-color: transparent;
        border: none;
      }
    }

    .filtersList {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .filterContainer {
        display: flex;
        align-items: center;
        margin: 1px 3px;
        color: var(--falcon-badge-soft-info-color);
        //border: 1px solid #00a5bb;
        //background-color: #00bcd4;
        background-color:  rgba(23, 141, 226, 0.705);
        width: fit-content;
        max-width: 100%;
        border-radius: 0.25rem;
        font-size: .78em;

        span {
          padding: 1.5px 4px;
        }

        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
          //background-color: #00bcd4;
        }
        .search {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-left: 1px solid white;
          color: white;
          font-style: italic;
        }
        .remover {
          display: flex;
          align-items: center;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .searchInputFlex {
        min-width: 33%;
        flex: 1 0 auto;
        -webkit-box-flex: 1;
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--custom-color);
      }
    }
  }
  // Value List
  .list {
    border: 1px solid black;
    border-top: none;
    padding: 5px;
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 151;
    max-height: 50vh;
    overflow: scroll;

    .propertyContainer {
      .identifier {
        display: grid;
        grid-template-columns: 15px auto;
        .label {
          text-align: left;
        }
      }
      .valueList {
        text-align: left;
      }
    }
    ul {
      list-style-type: none;
      margin-bottom: 0;

      li {
        padding: 2px 0px;
        .valueItem {
          white-space: nowrap;
          overflow: hidden;
          word-wrap: break-word;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.favoritesMenu {
  .favItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .selectFav {
      padding: 0.25rem 0.5rem;

      .favLabels {
        display: flex;
        justify-content: space-between;
        span {
          padding: 1px 4px;
        }
      }
    }
    .actionFav {
      cursor: pointer;
      padding: 2px 7.5px;

      &:hover {
        background-color: #e9ecef;
      }

      &.favDeleter {
        &:active {
          background-color: #ff3300;
          color: white;
        }
      }

      &.favDefault {
        &:active {
          background-color: #ffe600;
          color: black;
          opacity : 1;
        }
      }
    }
  }
}
