$maxMobileWidth: 500px;
$maxMobileHeight: 500px;

.accordion-button.collapsed {
  color: black;
}

//#region Bootstrap Badges Outline
.badge-outline {
  color: black;
  border: 1px solid #999;
  background-color: transparent;
}
.badge-outline.badge-outline-success {
  border: 1px solid #00d27a;
  color: #00d27a;
}
//#endregion

//#region Pulse
.pulse {
  background-color: red;
  // position: relative;
  animation: animate 1s linear infinite;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 109, 74, 0.7), 0 0 0 0 rgb(255, 109, 74, 0.7);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(255, 109, 74, 0), 0 0 0 12px rgb(255, 109, 74, 0);
  }
}
//#endregion

//#region Change react-bootstrap breadcrumb separator
.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important ;
}

button.btn-link-a {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
//#endregion

//#region Button Icon
i.button {
  cursor: pointer;
  padding: 5px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
}
//#endregion

//#region TypeAhead
.typeahead_better {
  .rbt-menu.dropdown-menu.show {
    min-width: 100% !important;
    width: fit-content !important;
  }
}
//#endregion

//#region min Heights
.min-vh-25 {
  min-height: 25vh;
}
.min-vh-33 {
  min-height: 33vh;
}
.min-vh-50 {
  min-height: 50vh;
}
.min-vh-66 {
  min-height: 66vh;
}
.min-vh-75 {
  min-height: 75vh;
}
// #endregion

//#region Width
.w-90 {
  width: 90% !important;
}
.w-10 {
  width: 10% !important;
}
//#endregion

//#region inline loader
.loader.inline-loader {
  position: relative;
  left: auto;
  top: auto;
  margin: 0;
  z-index: 20;
}

.loader_message_inline {
  padding: 0.25rem;
  font-size: large;
  font-weight: bold;
}
// #endregion

//#region Hover
.hover-bigger {
  &:hover {
    font-size: x-large;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
//#endregion

//#region z-indexes
.z-index-low {
  z-index: 5;
}
.z-index-mid {
  z-index: 100;
}
.z-index-high {
  z-index: 1000;
}
.z-index-higher-modal {
  z-index: 1301 !important;
}
.z-index-higher-loader {
  z-index: 10001;
}
// #endregion

//#region Margin Lateral
.mr-0 {
  margin-right: 0rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.ml-0 {
  margin-left: 0rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
//#endregion

//#region Police
.fs-65 {
  font-size: 0.65rem !important;
}
.fs-75 {
  font-size: 0.75rem !important;
}
.fs-85 {
  font-size: 0.85rem !important;
}
.fs-95 {
  font-size: 0.95rem !important;
}
.fs-100 {
  font-size: 1rem !important;
}
.fs-105 {
  font-size: 1.05rem !important;
}
.fs-110 {
  font-size: 1.1rem !important;
}
.fs-120 {
  font-size: 1.2rem !important;
}
.fs-130 {
  font-size: 1.3rem !important;
}
.fs-140 {
  font-size: 1.4rem !important;
}
.fs-150 {
  font-size: 1.5rem !important;
}
.fs-160 {
  font-size: 1.6rem !important;
}
.fs-170 {
  font-size: 1.7rem !important;
}
.fs-180 {
  font-size: 1.8rem !important;
}
.fs-190 {
  font-size: 1.9rem !important;
}
.fs-200 {
  font-size: 2rem !important;
}

.false_link {
  color: #2c7be5;
  &:hover {
    color: #1f5499;
    text-decoration: underline;
  }
}
// #endregion

//#region Formio
.hide-submit-formio {
  .btn.btn-primary[name="data[submit]"] {
    display: none !important;
  }
}
// #endregion

//#region Bootstrap extension
.fw-semibold {
  font-weight: 600 !important;
}

.smaller_buttons button {
  padding: 0.75rem;
}

.hover-opacity {
  &:hover {
    opacity: 0.5;
  }
}

.bg-emerald {
  background-color: #50c878 !important;
  color: "white" !important;
}

.btn-emerald {
  background-color: #50c878 !important;
  border-color: #50c878 !important;
}

.text-emerald {
  color: #50c878 !important;
}

.pointer {
  cursor: pointer;
}

.text-yellow {
  color: yellow;
}

.pointer-no {
  cursor: not-allowed;
}

.pointer-zoom {
  cursor: zoom-in;
}

.form-floating label {
  color: #0d6efd;
}

button.btn-none {
  background-color: transparent;
  border: none;
}

button.btn.stop-hiding:disabled {
  visibility: visible;
}

.invalid-feedback {
  &.shown {
    display: initial;
  }
  &.warning {
    color: #ffc107 !important;
  }
}

.container-xs {
  max-width: 275px;
}

.shadowed-hovered:hover {
  box-shadow: 0 0 7px rgba(#262626, 0.3);
}

.form-control.invalid {
  border-color: #dc3545;

  &:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.1rem #dc354673;
  }
}

.form-control.is-invalid.warning + .warning > .form-control.is-invalid {
  border-color: #ffc107 !important;
  box-shadow: 0 0 0 0.1rem #ffc10773 !important;
}

.striped {
  > *:nth-child(even) {
    background-color: whitesmoke;
  }
}

.hover_select {
  &:hover {
    border-color: #0d6efd !important;
    cursor: pointer;
  }
}

.hover_dark {
  :hover {
    background-color: #262626;
  }
}

.text-white {
  color: white !important;
}
// #endregion

//#region Animations
//animation swing-out-top-bck
@-webkit-keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
}
@keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
}

//animation swing-in-top-fwd
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

//animation scale-in-center
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

// animation slide-in-left
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

// animation slide-in-right
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

// animation fade-in
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// animation fade-in
@-webkit-keyframes fade-in-50 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes fade-in-50 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
// #endregion

//#region AG-Grid / Adaptable
div.ag-virtual-list-container.ag-filter-virtual-list-container {
  min-width: 100%;
  overflow: unset;
  width: fit-content;
  div.ag-virtual-list-item.ag-filter-virtual-list-item {
    min-width: fit-content;
    div.ag-set-filter-item {
      width: fit-content;
      div.ag-set-filter-item-checkbox.ag-labeled.ag-label-align-right.ag-checkbox.ag-input-field {
        min-width: fit-content;
        div.ag-input-field-label.ag-label.ag-checkbox-label.ag-label-ellipsis {
          min-width: fit-content;
        }
      }
    }
  }
}
//#endregion
